import React from 'react';
export default function AppLink({ text, onClick, ...rest }) {
	return (
		<button
			{...rest}
			onClick={onClick}
		>
			{text}
		</button>
	);
}

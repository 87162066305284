import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import AlertProvider from './context/AlertContext';
import UserProvider from './context/UserContext';
import './index.css';
import IntakeProvider from './context/IntakeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AlertProvider>
			<UserProvider>
				<IntakeProvider>
					<Router />
				</IntakeProvider>
			</UserProvider>
		</AlertProvider>
	</React.StrictMode>
);

import React, { useEffect, useState, Fragment } from 'react';
import Spinner from './Global/Spinner';
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
	EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { useIntake } from '../context/IntakeContext';
import DetailsHover from './DetailsHover';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function getDaysInMonth(month, year) {
	const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
	if (month === 2) {
		return isLeapYear ? 29 : 28;
	}
	return [4, 6, 9, 11].includes(month) ? 30 : 31;
}

export default function Calendar() {
	const [loading, setLoading] = useState(false);
	const [hoveredDay, setHoveredDay] = useState(null);
	const { dailyIntakes, recordIntake, fetchRecords, fetchDailyIntakes } =
		useIntake();

	const today = new Date();
	const currentMonth = today.getMonth() + 1; // getMonth() is zero-based
	const currentYear = today.getFullYear();

	const numberOfDays = getDaysInMonth(currentMonth, currentYear);

	const initialDays = Array.from({ length: numberOfDays }, (_, i) => {
		const date = new Date(currentYear, currentMonth - 1, i + 1);
		return {
			date: date.toISOString().slice(0, 10),
			intakes: 0,
		};
	});

	const [daysWithIntakes, setDaysWithIntakes] = useState(initialDays);
	async function handleData() {
		try {
			setLoading(true);
			await fetchDailyIntakes().then(() => {
				if (dailyIntakes) {
					const updatedDays = initialDays.map((day) => {
						const intakeForDay = dailyIntakes.find(
							(intake) => intake._id === day.date
						);
						return {
							...day,
							intakes: intakeForDay ? intakeForDay.count : 0,
						};
					});
					setDaysWithIntakes(updatedDays);
					setLoading(false);
				}
			});
		} catch (error) {
			setLoading(false);
			return console.log(error);
		}
	}

	// For emptySlots calculation
	const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
	const dayOfWeek = firstDayOfMonth.getDay(); // Day of week (0-6)
	const emptySlots = Array.from({ length: dayOfWeek }, () => null);

	useEffect(() => {
		handleData();
	}, []);

	function dateFormat(date) {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		const d = new Date(date);
		return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
	}

	const selectedDay = daysWithIntakes.find((day) => day.isSelected);
	if (loading) return <Spinner />;

	return (
		<div className='lg:flex lg:h-full lg:flex-col'>
			{/* Calendar Header */}
			<header className='flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none'>
				<h1 className='text-base font-semibold leading-6 text-gray-900'>
					<time dateTime={today.toISOString()}>
						{dateFormat(today.toISOString())}
					</time>
				</h1>
				<div className='flex items-center'>
					<div className='relative flex items-center rounded-md bg-white shadow-sm md:items-stretch'>
						<button
							type='button'
							className='flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50'
						>
							<span className='sr-only'>Previous month</span>
							<ChevronLeftIcon
								className='h-5 w-5'
								aria-hidden='true'
							/>
						</button>
						<button
							type='button'
							className='hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block'
						>
							Today
						</button>
						<span className='relative -mx-px h-5 w-px bg-gray-300 md:hidden' />
						<button
							type='button'
							className='flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50'
						>
							<span className='sr-only'>Next month</span>
							<ChevronRightIcon
								className='h-5 w-5'
								aria-hidden='true'
							/>
						</button>
					</div>
					<div className='hidden md:ml-4 md:flex md:items-center'>
						<Menu
							as='div'
							className='relative'
						>
							<Menu.Button
								type='button'
								className='flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							>
								Month view
								<ChevronDownIcon
									className='-mr-1 h-5 w-5 text-gray-400'
									aria-hidden='true'
								/>
							</Menu.Button>

							<Transition
								as={Fragment}
								enter='transition ease-out duration-100'
								enterFrom='transform opacity-0 scale-95'
								enterTo='transform opacity-100 scale-100'
								leave='transition ease-in duration-75'
								leaveFrom='transform opacity-100 scale-100'
								leaveTo='transform opacity-0 scale-95'
							>
								<Menu.Items className='absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
									<div className='py-1'>
										<Menu.Item>
											{({ active }) => (
												<a
													href='#'
													className={classNames(
														active
															? 'bg-gray-100 text-gray-900'
															: 'text-gray-700',
														'block px-4 py-2 text-sm'
													)}
												>
													Day view
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													href='#'
													className={classNames(
														active
															? 'bg-gray-100 text-gray-900'
															: 'text-gray-700',
														'block px-4 py-2 text-sm'
													)}
												>
													Week view
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													href='#'
													className={classNames(
														active
															? 'bg-gray-100 text-gray-900'
															: 'text-gray-700',
														'block px-4 py-2 text-sm'
													)}
												>
													Month view
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													href='#'
													className={classNames(
														active
															? 'bg-gray-100 text-gray-900'
															: 'text-gray-700',
														'block px-4 py-2 text-sm'
													)}
												>
													Year view
												</a>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
						<div className='ml-6 h-6 w-px bg-gray-300' />
						<button
							type='button'
							onClick={() => {
								recordIntake();
								window.location.reload();
							}}
							className='ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Add event
						</button>
					</div>
					<Menu
						as='div'
						className='relative ml-6 md:hidden'
					>
						<Menu.Button className='-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500'>
							<span className='sr-only'>Open menu</span>
							<EllipsisHorizontalIcon
								className='h-5 w-5'
								aria-hidden='true'
							/>
						</Menu.Button>

						<Transition
							as={Fragment}
							enter='transition ease-out duration-100'
							enterFrom='transform opacity-0 scale-95'
							enterTo='transform opacity-100 scale-100'
							leave='transition ease-in duration-75'
							leaveFrom='transform opacity-100 scale-100'
							leaveTo='transform opacity-0 scale-95'
						>
							<Menu.Items className='absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
								<div className='py-1'>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Create event
											</a>
										)}
									</Menu.Item>
								</div>
								<div className='py-1'>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Go to today
											</a>
										)}
									</Menu.Item>
								</div>
								<div className='py-1'>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Day view
											</a>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Week view
											</a>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Month view
											</a>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<a
												href='#'
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm'
												)}
											>
												Year view
											</a>
										)}
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
					</Menu>
				</div>
			</header>

			{/* Calendar Grid */}
			<div className='shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col'>
				{/* Weekday Headers */}
				<div className='grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none'>
					<div className='bg-white py-2'>
						S<span className='sr-only sm:not-sr-only'>un</span>
					</div>
					<div className='bg-white py-2'>
						M<span className='sr-only sm:not-sr-only'>on</span>
					</div>
					<div className='bg-white py-2'>
						T<span className='sr-only sm:not-sr-only'>ue</span>
					</div>
					<div className='bg-white py-2'>
						W<span className='sr-only sm:not-sr-only'>ed</span>
					</div>
					<div className='bg-white py-2'>
						T<span className='sr-only sm:not-sr-only'>hu</span>
					</div>
					<div className='bg-white py-2'>
						F<span className='sr-only sm:not-sr-only'>ri</span>
					</div>
					<div className='bg-white py-2'>
						S<span className='sr-only sm:not-sr-only'>at</span>
					</div>
				</div>

				{/* Days Grid */}
				<div className='flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto'>
					<div className='grid-cols-7 grid w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px'>
						{/* Render empty slots for alignment */}
						{emptySlots.map((_, index) => (
							<div
								key={index}
								className='relative px-3 py-2 bg-gray-50 text-gray-500'
							></div>
						))}
						{/* Render actual days */}
						{daysWithIntakes &&
							daysWithIntakes.map((day) => {
								return (
									<div
										key={day.date}
										onMouseEnter={() => setHoveredDay(day.date)}
										onMouseLeave={() => setHoveredDay(null)}
										className={classNames(
											'relative px-3 py-2',
											day.isCurrentMonth
												? 'bg-white'
												: 'bg-gray-50 text-gray-500'
										)}
									>
										{hoveredDay === day.date && <DetailsHover day={day} />}
										<time dateTime={day.date}>
											{day.date.split('-').pop().replace(/^0/, '')}
										</time>
										{day.intakes > 0 && (
											<div className='mt-2 text-xl sm:text-5xl text-center text-gray-600'>
												{`${day.intakes}`}
											</div>
										)}
									</div>
								);
							})}
					</div>
				</div>
				<div className='flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto'>
					<div className='hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px'></div>
				</div>
			</div>

			{/* Selected Day Events */}
			{selectedDay?.intakes > 0 && (
				<div className='px-4 py-10 sm:px-6 lg:hidden'>
					<ol className='divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5'>
						{selectedDay.events.map((event) => (
							<li
								key={event.id}
								className='group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50'
							>
								<div className='flex-auto'>
									<p className='font-semibold text-gray-900'>{event.name}</p>
									<time
										dateTime={event.datetime}
										className='mt-2 flex items-center text-gray-700'
									>
										<ClockIcon
											className='mr-2 h-5 w-5 text-gray-400'
											aria-hidden='true'
										/>
										{event.time}
									</time>
								</div>
								<a
									href={event.href}
									className='ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100'
								>
									Edit<span className='sr-only'>, {event.name}</span>
								</a>
							</li>
						))}
					</ol>
				</div>
			)}
		</div>
	);
}

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';

import client from '../../api/client';
import FormNavigator from '../AppForm/FormNavigator';
import SubmitBtn from '../AppForm/SubmitButton';

export default function Signup() {
	const { setAlert } = useAlert();
	const navigate = useNavigate();
	const styles = {
		input: {
			display: 'block',
			width: '100%',
			padding: 8,
			margin: 1,
			borderBottom: '1px solid #ccc',
		},
	};
	const { setIsLoggedIn, setUser, setLoginPending, loginPending } = useUser();

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = yup.object({
		email: yup
			.string()
			.email('Invalid email address.')
			.required('Email is missing.'),
		password: yup
			.string()
			.trim()
			.min(8, 'Password must be at least 8 characters.')
			.required('Password is missing.'),
	});

	const handleSignup = async (e) => {
		try {
			e.preventDefault();
			console.log('signing up');
			const values = {
				name: e.target.name.value,
				email: e.target.email.value,
				username: e.target.username.value,
				password: e.target.password.value,
			};
			const validate = validationSchema.validate(values);
			console.log({ validate });

			if (validate.error) {
				setAlert('error', validate.error);
				return console.error(validate.error);
			}
			setLoginPending(true);
			// this is the api call via axios
			const res = await client.post('/user/signup', { ...values });

			setLoginPending(false);
			if (!res.success) {
				console.error(res.error);
				setAlert('error', res.error);
				return;
			}
			// if successful login
			setUser(res.user);
			setIsLoggedIn(true);
			return navigate('/', { replace: true });
		} catch (error) {
			setLoginPending(false);
			setAlert('error', error.message);
			return console.error(error.message);
		}
	};

	return (
		<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
					Sign up
				</h2>
				<div style={{ mt: 3 }}>
					<form onSubmit={handleSignup}>
						<div
							container
							spacing={2}
						>
							<div
								item
								xs={12}
								sm={6}
							>
								<input
									placeholder='Name'
									name='name'
									required
									style={styles.input}
									label='Full Name'
									autoComplete='given-name'
									autoFocus
								/>
							</div>
							<div
								item
								xs={12}
								sm={6}
							>
								<input
									placeholder='Username'
									name='username'
									style={styles.input}
									required
									label='Username'
								/>
							</div>
							<div
								item
								xs={12}
							>
								<input
									placeholder='Email'
									name='email'
									required
									autoCapitalize='none'
									style={styles.input}
									type='text'
									autoComplete='email'
									label='Email Address'
								/>
							</div>

							<input
								autoCapitalize='none'
								placeholder='Password'
								name='password'
								style={styles.input}
								label='Password'
								required
								type='password'
								autoComplete='new-password'
							/>
						</div>
						<SubmitBtn
							title='Signup'
							type='submit'
							className='bg-indigo-500 w-full hover:bg-indigo-700 text-white px-2 py-1 rounded-b-md'
							isSubmitting={loginPending === true}
						/>

						<FormNavigator
							onLeftLinkPress={() => navigate('/login', { replace: true })}
							onRightLinkPress={() =>
								navigate('/forgot-password', { replace: true })
							}
							leftLinkText='Login'
							rightLinkText='Forgot Password'
						/>
					</form>
				</div>
			</div>
		</div>
	);
}

import React, { useState } from 'react';
export default function ResendVerification({}) {
	const [showForm, setShowForm] = useState(false);

	const toggleElements = () => {
		showForm ? setShowForm(false) : setShowForm(true);
	};

	return (
		<div>
			<button
				variant='contained'
				onClick={toggleElements}
			>
				Resend Verification Email
			</button>
			{showForm ? <input placeholder='email for reverify' /> : null}
		</div>
	);
}

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import client from '../../../api/client';
import { useAlert } from '../../../context/AlertContext';
import { useUser } from '../../../context/UserContext';
import { ACCESS_TOKEN_KEY } from '../../../utils/auth';
import Spinner from '../../Global/Spinner';
import ResendVerification from './ResendVerification';
const Verify = ({ route }) => {
	const isObjValid = (obj) => {
		// convert values to array
		// every method returns true if every value passes the condition
		return Object.values(obj).every((value) => value.trim());
	};

	const inputs = Array(4).fill('');
	const navigate = useNavigate();
	const { setAlert } = useAlert();

	const [OTP, setOTP] = useState({ 0: '', 1: '', 2: '', 3: '' });
	const input = useRef();
	let newInputIndex = 0;
	const [nextInputIndex, setNextInputIndex] = useState(0);
	const { setIsLoggedIn, setUser, loginPending, setLoginPending } = useUser();

	const submitOTP = async () => {
		setLoginPending(true);
		if (!isObjValid(OTP)) {
			setLoginPending(false);
			return setAlert('error', 'Invalid Passcode');
		}
		if (isObjValid(OTP)) {
			let val = '';
			Object.values(OTP).forEach((v) => {
				val += v;
			});

			let token = sessionStorage.getItem(ACCESS_TOKEN_KEY);

			const { data } = await client.post('/user/verify', {
				val,
				token,
			});
			setLoginPending(false);
			if (!data.success) {
				setLoginPending(false);
				return setAlert('error', data.error);
			}

			if (data.success) {
				setLoginPending(false);
				navigate('/signin', { replace: true });
			}
			return;
		}
	};
	const handleChangeText = (e, index) => {
		const { value } = e.target;

		const newOTP = { ...OTP };
		newOTP[index] = value;
		setOTP(newOTP);
		if (!value) {
			newInputIndex = index === 0 ? 0 : index - 1;
		}

		const lastInputIndex = inputs.length - 1;
		newInputIndex = index === lastInputIndex ? lastInputIndex : index + 1;
		setNextInputIndex(newInputIndex);
	};

	useEffect(() => {
		input.current.focus();
	}, [nextInputIndex]);

	return (
		<>
			{loginPending ? <Spinner /> : null}

			<div style={{ ...styles.container }}>
				<h1 style={{ textAlign: 'center', fontSize: 48 }}>
					Verify Your Account
				</h1>
				<h2 style={styles.heading}>
					A PIN has been sent to your email for verification. Please verify your
					email.
				</h2>
				<div style={{ ...styles.otpContainer, display: 'flex' }}>
					{inputs.map((inp, index) => {
						return (
							<div
								style={{ ...styles.inputContainer }}
								key={index.toString()}
							>
								<input
									style={styles.input}
									placeholder='0'
									onChange={(e) => handleChangeText(e, index)}
									value={OTP[index]}
									maxLength={1}
									ref={nextInputIndex === index ? input : null}
								/>
							</div>
						);
					})}
				</div>
				<div style={{ textAlign: 'center' }}>
					<button
						style={styles.submitIcon}
						onClick={submitOTP}
					>
						<p style={{ ml: 1 }}>Submit</p>
					</button>
					<div style={{ mt: 2 }}>
						<ResendVerification />
					</div>
				</div>
			</div>
		</>
	);
};

const styles = {
	container: {
		// justifyContent: "center",
		// display: "flex",
		width: '100%',
	},
	heading: {
		color: 'black',
		textAlign: 'center',
		marginBottom: 15,
	},
	inputContainer: {
		borderWidth: 2,
		// justifyContent: "center",
		// alignItems: "center",
		// flexDirection: "row",
		// display: "flex",
		// width: "200px",
		fontSize: 36,
		borderColor: 'black',
	},
	input: {
		fontSize: 36,
		textAlign: 'center',
		margin: 3,
		width: '45px',
	},
	otpContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	submitIcon: {
		alignSelf: 'center',
		// borderRadius: 50,
		marginTop: 5,
	},
};

export default Verify;

import React, { createContext, useContext, useState, useEffect } from 'react';
import client from '../api/client';
import { useUser } from './UserContext';
const IntakeContext = createContext();
const IntakeProvider = ({ children }) => {
	const [records, setRecords] = useState([]);
	const [dailyIntakes, setDailyIntakes] = useState([]);
	const [showClearAllModal, setShowClearAllModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [currentRecordId, setCurrentRecordId] = useState(null);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const openDatePicker = (recordId) => {
		setCurrentRecordId(recordId);
		setSelectedDate(new Date()); // Reset to current date or fetch the current date of the record
		setShowDatePicker(true);
	};

	const updateIntake = async () => {
		setShowDatePicker(false);
		if (currentRecordId) {
			await client.put(`/meds/${currentRecordId}`, {
				intakeTime: selectedDate.toISOString(),
			});
			fetchRecords();
			fetchDailyIntakes();
		}
	};

	const confirmClearAll = async () => {
		setShowClearAllModal(false);
		clearAllIntakes();
	};

	const confirmDelete = async () => {
		setShowDeleteModal(false);
		deleteIntake(currentRecordId);
	};
	const fetchRecords = async () => {
		const response = await client.get('/meds');
		setRecords(response.data);
	};

	const fetchDailyIntakes = async () => {
		try {
			const response = await client.get('/meds/by-day');
			if (response.data) setDailyIntakes(response.data);
		} catch (error) {
			return console.error(error);
		}
	};

	const recordIntake = async () => {
		await client.post('/meds');
		fetchRecords();
		fetchDailyIntakes();
	};

	const clearAllIntakes = async () => {
		await client.delete('/meds/clear-all');
		fetchRecords();
		fetchDailyIntakes();
	};

	const deleteIntake = async (id) => {
		await client.delete(`/meds/delete/${id}`);
		fetchRecords();
		fetchDailyIntakes();
	};

	const handleDeleteClick = (id) => {
		setCurrentRecordId(id);
		setShowDeleteModal(true);
	};
	const { user, isLoggedIn } = useUser();
	useEffect(() => {
		if (user) {
			fetchRecords();
			fetchDailyIntakes();
		}
	}, [user]);
	if (!children) return console.log('no children');
	return (
		<IntakeContext.Provider
			value={{
				records,
				setRecords,
				dailyIntakes,
				setDailyIntakes,
				showClearAllModal,
				setShowClearAllModal,
				showDeleteModal,
				setShowDeleteModal,
				currentRecordId,
				setCurrentRecordId,
				showDatePicker,
				setShowDatePicker,
				selectedDate,
				setSelectedDate,
				openDatePicker,
				deleteIntake,
				handleDeleteClick,
				clearAllIntakes,
				recordIntake,
				confirmDelete,
				confirmClearAll,
				updateIntake,
				fetchRecords,
				fetchDailyIntakes,
			}}
		>
			{children}
		</IntakeContext.Provider>
	);
};

/**
 * 
 * 	fetchRecords
				fetchDailyIntakes,

* 	records
				
				setRecords
				
				dailyIntakes
				
				setDailyIntakes
				
				showClearAllModal
				
				setShowClearAllModal
				
				showDeleteModal
				
				setShowDeleteModal
				
				currentRecordId
				
				setCurrentRecordId
				
				showDatePicker
				
				setShowDatePicker
				
				selectedDate
				
				setSelectedDate
				
				openDatePicker
				
				deleteIntake
				
				handleDeleteClick
				
				clearAllIntakes
				
				recordIntake
				
				confirmDelete
				
				confirmClearAll
				
				updateIntake
				
			
 * @returns 
 */
export const useIntake = () => useContext(IntakeContext);
export default IntakeProvider;

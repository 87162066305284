import React from 'react';
import AppLink from './AppLink';
export default function FormNavigator({
	leftLinkText,
	rightLinkText,
	onLeftLinkPress,
	onRightLinkPress,
}) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<AppLink
				onClick={onLeftLinkPress}
				text={leftLinkText}
			/>
			<AppLink
				onClick={onRightLinkPress}
				text={rightLinkText}
			/>
		</div>
	);
}

import React, { useState, useEffect } from 'react';
import client from '../api/client';
import Calendar from './Calendar';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerCustomStyles.css'; // Import your custom CSS file for additional styling

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import ConfirmModal from './Global/ConfirmModal';
import { useIntake } from '../context/IntakeContext';

const MedicineTracker = () => {
	const {
		records,
		setRecords,
		dailyIntakes,
		setDailyIntakes,
		showClearAllModal,
		setShowClearAllModal,
		showDeleteModal,
		setShowDeleteModal,
		currentRecordId,
		setCurrentRecordId,
		showDatePicker,
		setShowDatePicker,
		selectedDate,
		setSelectedDate,
		openDatePicker,
		deleteIntake,
		handleDeleteClick,
		clearAllIntakes,
		recordIntake,
		confirmDelete,
		confirmClearAll,
		updateIntake,
		fetchRecords,
		fetchDailyIntakes,
	} = useIntake();
	useEffect(() => {
		fetchRecords();
		fetchDailyIntakes();
	}, []);

	return (
		<div className='container mx-auto p-4'>
			<div className='flex justify-between mb-4'>
				<button
					onClick={recordIntake}
					className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
				>
					Record Intake
				</button>
				<button
					onClick={() => setShowClearAllModal(true)}
					className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
				>
					Clear All
				</button>
			</div>

			<h2 className='text-xl font-semibold mb-3'>Past Records</h2>
			<ul
				role='list'
				className='divide-y divide-gray-100'
			>
				{records.map((record) => (
					<li
						key={record._id}
						className='flex items-center justify-between gap-x-1 py-2'
					>
						<div className='min-w-0'>
							<div className='flex items-start gap-x-1'>
								<p className='text-sm font-semibold leading-6 text-gray-900'>
									{new Date(record.intakeTime).toLocaleTimeString()}
								</p>
							</div>
							<div className='mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500'>
								{/* Additional details here, if any */}
								{new Date(record.intakeTime).toDateString()}
							</div>
						</div>
						<div className='flex flex-none items-center gap-x-4'>
							<button
								onClick={() => openDatePicker(record._id)}
								className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							>
								Edit <PencilIcon className='w-5 h-5 ml-1 inline' />
							</button>
							<button
								onClick={() => handleDeleteClick(record._id)}
								className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							>
								Delete <TrashIcon className='w-5 h-5 ml-1 inline' />
							</button>
						</div>
					</li>
				))}
			</ul>

			{showDatePicker && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
					<div className='bg-white p-4 rounded-lg shadow-lg'>
						<DatePicker
							selected={selectedDate}
							timeIntervals={5}
							onChange={(date) => setSelectedDate(date)}
							showTimeSelect
							dateFormat='Pp'
							className='form-input w-full' // Tailwind class for input styling
							calendarClassName='react-datepicker-custom' // Custom class for additional styling
						/>
						<div className='flex justify-end mt-4'>
							<button
								onClick={() => setShowDatePicker(false)}
								className='bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded mr-2'
							>
								Cancel
							</button>
							<button
								onClick={updateIntake}
								className='bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded'
							>
								Update
							</button>
						</div>
					</div>
				</div>
			)}

			<ConfirmModal
				isOpen={showClearAllModal}
				onClose={() => setShowClearAllModal(false)}
				onConfirm={confirmClearAll}
				message='Are you sure you want to clear all records?'
			/>

			<ConfirmModal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={confirmDelete}
				message='Are you sure you want to delete this record?'
			/>
		</div>
	);
};

export default MedicineTracker;

import axios from 'axios';

let isProd = window.location.href.includes('keiserdev') ? true : false;

const client = axios.create({
	baseURL: isProd
		? 'https://meds.keiserdev.com/api'
		: 'http://localhost:5099/api',

	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
});
export default client;

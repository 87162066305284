import { useFormikContext } from 'formik';
import React from 'react';
import Spinner from '../Global/Spinner';

export default function SubmitBtn({ title, isSubmitting, ...rest }) {
	return (
		<button
			{...rest}
			sx={{
				backgroundColor: isSubmitting ? '#E8E8E8' : 'primary',
				width: '100%',
			}}
		>
			{isSubmitting ? <Spinner /> : title}
		</button>
	);
}

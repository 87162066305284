import React from 'react';
import { Outlet } from 'react-router-dom';
import Auth from '../utils/auth';
import { useUser } from '../context/UserContext';
import Navbar from './Navbar';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}
const MainLayout = () => {
	const { user, isLoggedIn } = useUser();

	return (
		<div className=''>
			<Navbar />
			<Outlet />
		</div>
	);
};

export default MainLayout;

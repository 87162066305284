import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';
import signin, { ACCESS_TOKEN_KEY } from '../../utils/auth';
import AppFormContainer from '../AppForm/AppFormContainer';
import CustomFormik from '../AppForm/CustomFormik';
import FormNavigator from '../AppForm/FormNavigator';
import client from '../../api/client';

export default function Login() {
	const { setIsLoggedIn, isLoggedIn, setUser, setLoginPending } = useUser();
	const { setAlert } = useAlert();

	const initialValues = {
		email: '',
		password: '',
	};

	const navigate = useNavigate();

	const validationSchema = yup.object({
		email: yup
			.string()
			.email('Invalid email address.')
			.required('Email is missing.'),
		password: yup
			.string()
			.trim()
			.min(8, 'Password must be at least 8 characters.')
			.required('Password is missing.'),
	});
	const styles = {
		input: {
			display: 'block',
			width: '100%',
			padding: 8,
			margin: 4,
			borderBottom: '1px solid #ccc',
			borderRadius: 4,
		},
	};
	const handleLogin = async (e) => {
		try {
			e.preventDefault();
			const values = {
				email: e.target.email.value,
				password: e.target.password.value,
			};
			console.log('logging in');
			console.log({ values });
			setLoginPending(true);
			// this is the api call via axios
			const { data } = await client.post('/user/signin', {
				...values,
			});

			if (!data.success) {
				setAlert('error', data.error);
				console.error(data.error);
				return console.log(data.error);
			}
			if (data) {
				setLoginPending(false);

				// if successful login
				setUser(data.user);
				if (data.token) sessionStorage.setItem(ACCESS_TOKEN_KEY, data.token);
				setIsLoggedIn(true);
				if (data.user && data.user.isVerified) {
					navigate('/dashboard', { replace: true });
				} else {
					navigate('/verify', { replace: true });
				}
			}
		} catch (error) {
			setLoginPending(false);
			setAlert('error', error?.response?.data?.error);
			return console.error(error?.response?.data?.error);
		}
	};
	if (isLoggedIn) {
		navigate('/', { replace: true });
	} else
		return (
			<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
				<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						Sign in
					</h2>
					<AppFormContainer>
						<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
							<form
								className='space-y-6'
								onSubmit={handleLogin}
							>
								<div className='grid grid-flow-row max-w-lg'>
									<div>
										<input
											id='email'
											variant='filled'
											placeholder='Email'
											label='Email'
											required
											name='email'
											autoCapitalize='none'
											style={styles.input}
										/>
									</div>
									<div>
										<input
											id='password'
											required
											variant='filled'
											autoCapitalize='none'
											autoComplete='current-password'
											placeholder='Password'
											name='password'
											type='password'
											label='Password'
											style={styles.input}
										/>
									</div>

									<button
										type='submit'
										className='mt-2 mb-2 bg-indigo-500 text-white px-2 py-1 rounded-md hover:bg-indigo-700'
									>
										Login
									</button>
								</div>
							</form>
						</div>
					</AppFormContainer>
					<FormNavigator
						onLeftLinkPress={() => navigate('/signup', { replace: true })}
						onRightLinkPress={() =>
							navigate('/forgot-password', { replace: true })
						}
						leftLinkText='Sign Up'
						rightLinkText='Forgot Password'
					/>
				</div>
			</div>
		);
}
